<template>
    <div class="opinions">
        <div class="opinions__container">
            <div class="opinions__container__tile" data-aos="fade-up" data-aos-duration="1500">
                <div class="opinions__container__tile__image">
                    <img src="../assets/opinion.svg" alt="Monitory poleasingowe, tanie laptopy oraz komputery w ofercie outletu poleasingowego.">
                </div>
                <div class="opinions__container__tile__name">
                    <span>Ewa</span>
                </div>
                <div class="opinions__container__tile__content">
                    <span>Polecam serdecznie! Wszystko sprawnie, bez komplikacji. Zamówiony laptop jest w stanie idealnym! Na pewno jeszcze wrócę do zakupów.</span>
                </div>
            </div>
            <div class="opinions__container__tile" data-aos="fade-up" data-aos-duration="1000">
                <div class="opinions__container__tile__image">
                    <img src="../assets/opinion.svg" alt="Monitory poleasingowe, tanie laptopy oraz komputery w ofercie outletu poleasingowego.">
                </div>
                <div class="opinions__container__tile__name">
                    <span>Michal M</span>
                </div>
                <div class="opinions__container__tile__content">
                    <span>Sprzet w doskonalym stanie. To byl kolejny zakup w Kompre, poprzedni rownie udany. Naprawde polecam!</span>
                </div>
            </div>
            <div class="opinions__container__tile" data-aos="fade-up" data-aos-duration="500">
                <div class="opinions__container__tile__image">
                    <img src="../assets/opinion.svg" alt="Monitory poleasingowe, tanie laptopy oraz komputery w ofercie outletu poleasingowego.">
                </div>
                <div class="opinions__container__tile__name">
                    <span>Piotr</span>
                </div>
                <div class="opinions__container__tile__content">
                    <span>Kupiłem kilka laptopów od tego sprzedawcy. Zawsze wszystko przebiegło bardzo dobrze. Nie miałem też żadnych awarii. Polecam.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
    mounted(){
    },
  components: {},
}
</script>
<style lang="scss" scoped>
// Import variables
@import '../styles/variables';

.opinions
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: auto;
    margin-top: 2.441rem;
    margin-bottom: 2.441rem;
    &__container
    {
        width: 90%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__tile{
                display: flex;
                flex-direction: column;
                width: 31%;
                transition: box-shadow 0.4s ease-in-out;
                overflow: hidden;
                border-radius: 0.64rem;
                box-shadow: 0px 3px 12px hsla(0,0%, 0%, .1);
                padding-top: 1.563rem;
                padding-bottom: 1.953rem;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                &__image{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0.5;
                    margin-left: 1rem;
                    height: 2.441rem;
                    width: 2.441rem;
                    position: absolute;
                    img{
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
                &__name{
                    margin-top: 0.21rem;
                    margin-bottom: 0.41rem;
                    margin-left: auto;
                    margin-right: auto;
                    span{
                        font-size: 1.25rem;
                        font-weight: 500;
                    }
                }
                &__content{
                    margin-left: auto;
                    margin-right: auto;
                    width: 85%;
                    text-align: center;
                    span{
                        font-size: 0.8rem;
                        text-align: center;
                    }
                }
        }
    }
}

    @media (max-width: 769px)
    {
        .opinions__container{
            flex-direction: column;
            align-items: flex-start;
            &__tile{
                width: 100%;
                margin-bottom: 1.563rem;
            }
            &__tile:last-child{
                margin-bottom: 0;
            }
        }
    }
</style>