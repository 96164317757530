<template>
    <div class="newsletter">
        <div :class="{'newsletter__columns': true, 'afterRegister': newsletterRegistered}">
            <div class="newsletter__columns__inputColumn" v-if="newsletterRegistered === false" data-aos="fade-right">
                <span class="newsletter__columns__inputColumn__header">Newsletter z dodatkowym rabatem 5%!</span>
                <div class="newsletter__columns__inputColumn__inputRow">
                    <input :class="{'errorMail': this.errors.mail}" @change="emailExist = false" placeholder="Podaj swój e-mail" type="text" v-model="email">
                    <button @click="checkForErrors()" :class="{'opacity': loading}" :disabled="loading">Zapisz się!</button>
                </div>
                <span class="error" v-if="emailExist">Podany mail jest już zapisany do newslettera.</span>
                <span class="error" v-if="errors.mail">Musisz podać adres e-mail.</span>
                <span class="error" v-if="errors.rules">Aby zapisać się do newslettera, musisz zaakceptować zasady.</span>
            </div>
            <div class="newsletter__columns__inputColumn" v-if="newsletterRegistered === true">
                <span class="newsletter__columns__inputColumn__header">Dziękujemy za rejestrację do newslettera!</span>
                <span class="newsletter__columns__inputColumn__text">Wkrótcę otrzymasz wiadomość e-mail potwierdzającą rejestrację wraz z kodem rabatowym.</span>
            </div>
            <div class="newsletter__columns__socialColumn" data-aos="fade-left">
                <span class="newsletter__columns__socialColumn__header">Znajdziesz nas na:</span>
                <div class="newsletter__columns__socialColumn__photosRow">
                    <a href="https://www.facebook.com/komprepl" target="_blank">
                    <svg viewBox="0 0 24 24">
                            <path d="M24,12.073c0,5.989-4.394,10.954-10.13,11.855v-8.363h2.789l0.531-3.46H13.87V9.86c0-0.947,0.464-1.869,1.95-1.869h1.509   V5.045c0,0-1.37-0.234-2.679-0.234c-2.734,0-4.52,1.657-4.52,4.656v2.637H7.091v3.46h3.039v8.363C4.395,23.025,0,18.061,0,12.073   c0-6.627,5.373-12,12-12S24,5.445,24,12.073z"/>
                    </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="newsletter__rules" v-if="newsletterRegistered === false" data-aos="fade-right" data-aos-offset="20">
            <div :class="{'checkbox': true, 'errorRules': this.errors.rules}">
                <input @change="checkRules()" type="checkbox" id="rules" name="check" v-model="rules" />
                <label for="rules"></label>
            </div>
            <div class="newsletter__rules__text"><span @click="rules = !rules">Chcę otrzymywać od Kompre Sp. z o.o. na podany przeze mnie adres e-mail informacje marketingowe dotycznące Kompre.pl, spółki Kompre Sp. z o.o. oraz partnerów biznesowych. Zgodnie z politką prywatności.</span></div>
        </div>
        <div class="newsletter__columnsMobile">
            <div class="newsletter__columnsMobile__socialColumnMobile" data-aos="fade-up">
                <span class="newsletter__columnsMobile__socialColumnMobile__header">Znajdziesz nas na:</span>
                <div class="newsletter__columnsMobile__socialColumnMobile__photosRow">
                    <a href="https://www.facebook.com/komprepl" target="_blank">
                    <svg viewBox="0 0 24 24">
                            <path d="M24,12.073c0,5.989-4.394,10.954-10.13,11.855v-8.363h2.789l0.531-3.46H13.87V9.86c0-0.947,0.464-1.869,1.95-1.869h1.509   V5.045c0,0-1.37-0.234-2.679-0.234c-2.734,0-4.52,1.657-4.52,4.656v2.637H7.091v3.46h3.039v8.363C4.395,23.025,0,18.061,0,12.073   c0-6.627,5.373-12,12-12S24,5.445,24,12.073z"/>
                    </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // Imports
    import {
    db, firebase
    } from '../configs/firebase'
    import axios from 'axios';

    export default {
        data() {
            return {
                email: "",
                rules: false,
                emailExist: false,
                newsletterRegistered: false,
                loading: false,
                errors:{
                    mail: false,
                    rules: false,
                }
            }
        },
        methods: {
            async generateCode()
            {
                let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < 6; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                const freeCode = await db.collection("Discounts").doc(result).get();
                return {
                    code: result,
                    exists: freeCode.exists
                }
            },
            async newsletterSignUp(){
                this.loading = true;
                let freeCode = null;
                for(let i=0; i < 999; i++)
                {
                    const resultOfGettingCode = await this.generateCode();
                    if(!resultOfGettingCode.exists)
                    {
                        freeCode = resultOfGettingCode;
                        break;
                    }
                };
                if(freeCode !== null){
                
                await db.collection("Newsletter").doc("Registered").get().then((snapshot)=>
                {
                    let data = snapshot.data();
                    for(let index in data.Emails)
                    {
                        if(data.Emails[index] === this.email){
                            this.emailExist = true;
                            return 0
                        }
                    }
                }).catch((error)=>
                {
                    console.log(`Could not load categories: ${error}`);
                })
                if(this.emailExist === false){
                    await db.collection('Newsletter').doc('Registered').update({
                                'Emails':  firebase.firestore.FieldValue.arrayUnion(this.email)
                            });
                    let categories = []
                    await db.collection("Categories").where('showInDashboard',"==", true).get().then((snapshot)=>
                    {
                        for(let index in snapshot.docs)
                        {
                            categories.push(snapshot.docs[index].data().name);
                        }
                    }).catch((error)=>
                    {
                        console.log(`Could not load categories: ${error}`);
                    })

                    const code = freeCode.code;

                    const codeData = {
                        category: categories,
                        type: "percent",
                        value: 5,
                        oneUse: true,
                        minValue: 0,
                        uses: 0,
                        products: []
                    };
                    try
                        {
                    const result = await db.collection('Discounts').doc(code).set(codeData);
                    }
                    catch (error)
                    {
                        console.log('Couldnt add code to db. ', error); 
                        return 0;
                    };

                    const preparedData = {
                        mail: this.email,
                        code: code
                    };
                    await axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/NewsletterConfirmation', preparedData).catch((error)=>
                    {
                        console.log(error);
                    })
                    this.newsletterRegistered = true;
                    }
                    }else{
                        alert('cos poszlo nie tak')
                    }
                    this.loading = false;
            },

            checkForErrors(){
                if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email))
                    {
                        this.errors.mail = false;
                        if(this.rules === true){
                            this.errors.rules = false;
                            this.newsletterSignUp();
                        }
                        else{
                            this.errors.rules = true;
                            return 0;
                        }
                    }
                    else{
                        this.errors.mail = true;
                        return 0;
                    }
            },
            checkRules(){
                if(this.rules === true){
                    this.errors.rules = false;
                }
            }
        },
        watch:
        {

        },
    }
</script>
<style lang="scss" scoped>
  // Color Variables Import
  @import '../styles/variables';

.errorMail{
    color: #d60000 !important;
    border: 1px solid #d60000 !important;
}
.errorMail::placeholder{
    color: #d60000 !important;
}

.opacity{
    opacity: 0.5;
}

.error{
    font-size: 0.8rem;
    color: #d60000;
    text-align: center;
}
.errorRules{
    label{
    box-shadow: inset 0px 1px 1px rgba(214, 0, 0, 0.8), 0px 1px 3px rgba(214, 0, 0, 1) !important;
    }
}
.afterRegister{
    padding-bottom: 1.953rem !important;
}

.checkbox {
    margin-top: auto;
    margin-bottom: auto;
  width: 15px;
  height: 15px;
  position: relative;
  label {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    transition: all 0.2s ease-in-out;
    &:after {
      content: '';
      width: 7px;
      height: 4px;
      position: absolute;
      top: 3px;
      left: 3px;
      border: 2px solid $red;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
      transition: all 0.2s ease-in-out;
    }
    &:hover::after {
      opacity: 0.2;
    }
  }
  label:hover{
      background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }    
}

    .newsletter
    {
            width: 100vw;
            background: #eeeeee;
            display: flex;
            height: auto;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 1.563rem;
            margin-top: 2.441rem;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
        &__rules{
            display: flex;
            width: 61.5%;
            padding-top: 0.64rem;
            padding-bottom: 1.953rem;
            &__text{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0;
                margin-left: 0.41rem;
                font-size: 0.64rem;
                    line-height: 0.8rem !important;
                    color: #757575;
            }
        }
        &__columns{
            display: flex;
            justify-content: center;
            padding-top: 1.953rem;
            width: 70%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &__inputColumn{
                width: 60%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                &__header{
                    font-size: 1.25rem;
                    color: #000000;
                    font-weight: 500;
                    margin-bottom: 0.64rem;
                }
                &__text{
                    font-size: 0.8rem;
                    color: #000000;
                    font-weight: 400;
                    text-align: center;
                }
                &__inputRow{
                    display: flex;
                    width: 80%;
                    height: 3.052rem;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    input{
                        font-size: 1rem;
                        font-weight: 500;
                        font-family: 'Poppins', sans-serif !important;
                        border: none;
                        border: 1px solid #000000;
                        border-right: 1px solid rgb(255, 255, 255, 0);
                        border-radius: 0;
                        outline: none;
                        transition: all 0.3s ease-in-out;
                        padding-left: 0.64rem;
                        padding-right: 0.64rem;
                        width: 70%;
                        height: 100%;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        // cursor: pointer;
                        background: none;
                    }
                    input:hover, input:focus{
                        border: 1px solid $red;
                    }
                    input::placeholder{
                        font-size: 1rem;
                        font-weight: 400;
                        color: #9e9e9e;
                        transition: all 0.3s ease-in;
                    }
                    input:hover::placeholder,input:focus::placeholder{
                        color: #7d7d7d;
                    }
                    button{
                        width: 30%;
                        height: 100%;
                        border: none;
                        border: 1px solid #000000;
                        border-radius: 0;
                        box-sizing: border-box;
                        font-family: 'Poppins', sans-serif !important;
                        font-weight: 400;
                        font-size: 1rem;
                        background: #484848;
                        outline: none;
                        cursor: pointer;
                        color: #ffffff;
                        transition: all 0.3s ease-in-out;
                    }
                    button:hover{
                        // border: 1px solid $red;
                        background: #6a6a6a;
                        // color: #e0e0e0;
                    }
                }
            }
            &__socialColumn{
                width: 40%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // background: green;
                &__header{
                    font-size: 1.25rem;
                    color: #000000;
                    font-weight: 500;
                    margin-bottom: 0.64rem;
                }
                &__photosRow{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80%;
                    height: 3.052rem;
                    // background: red;
                                -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg{
                            height: 3rem;
                            width: 3rem;
                            fill: #484848;
                            cursor: pointer !important;
                            transition: fill 0.2s ease-in-out;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                        }
                        svg:hover{
                            fill: #4267B2;
                        }
                    }
                }
            }
        }
        &__columnsMobile{
            display: none;
        }
    }

    @media (max-width: 1178px)
    {
        .newsletter__columns__inputColumn__inputRow{
            width: 100%;
        }
        .newsletter__rules{
            width: 70%;
        }
    }
    @media (max-width: 940px)
    {
        .newsletter__columns__inputColumn__header{
            font-size: 1rem;
        }
        .newsletter__columns__socialColumn__header{
            font-size: 1rem;
        }
    }
    @media (max-width: 769px)
    {
        .newsletter__columns{
            width: 90%;
        }
        .newsletter__columnsMobile{
            width: 90%;
        }
        .newsletter__columns__socialColumn{
            display: none;
        }
        .newsletter__columns__inputColumn{
            width: 100%;
        }
        .newsletter__rules{
            width: 90%;
            padding-bottom: 1rem;
        }
        .newsletter__columnsMobile{
            padding-bottom: 1.953rem;
            display: flex;
            justify-content: center;
            width: 70%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &__socialColumnMobile{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // background: green;
                &__header{
                    font-size: 1rem;
                    color: #000000;
                    font-weight: 500;
                    margin-bottom: 0.64rem;
                }
                &__photosRow{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80%;
                    height: 3.052rem;
                    // background: red;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        svg{
                            height: 2.441rem;
                            width: 2.441rem;
                            fill: #484848;
                            cursor: pointer !important;
                            transition: fill 0.2s ease-in-out;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                        }
                        svg:hover{
                            fill: #4267B2;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 452px)
    {
        .error{
            font-size: 0.64rem;
        }
        .newsletter__columns__inputColumn__inputRow{
            height: 2.441rem;
            button{
                font-size: 0.8rem;
            }
            input::placeholder{
                font-size: 0.8rem;
            }
            input{
                font-size: 0.8rem;
            }
        }
        // .newsletter__columns__inputColumn__header{
        //     font-size: 0.8rem;
        // }
        // .newsletter__columnsMobile__socialColumnMobile__header{
        //     font-size: 0.8rem;
        // }
        .newsletter__rules__text{
            font-size: 0.41rem;
        }
    }
@media (max-width: 350px)
    {
        .newsletter__columns__inputColumn__header{
            font-size: 0.8rem;
        }
        .newsletter__columnsMobile__socialColumnMobile__header{
            font-size: 0.8rem;
        }
    }


</style>