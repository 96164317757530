<template>
    <div class="banner">
        <div class="banner__container">
            <div class="banner__container__wrapper animate__animated animate__fadeIn animate__delay-0.2s" v-show="showSlide">
                <div class="banner__container__slide">
                    <a :href="currentSlide.link.includes('https://') ? currentSlide.link : ('https://' + currentSlide.link)">
                        <img :src="currentSlide.imgUrl" :alt="currentSlide.alt" class="banner__container__image animate__animated animate__fadeInLeft animate__delay-0.5s" />
                    </a>
                </div>
            </div>
        </div>
        <div class="banner__control">
            <div class="banner__control__dots">
                <div v-for="slide in slides" :key="slide.title" :class="{'banner__control__dots__dot': true,'banner__control__dots__dotActive': currentSlide === slide}" @click="slidePick(slide, slides.indexOf(slide))"></div>
            </div>
        </div>
    </div>

</template>
<script>
// Imports
import {
  db
} from '../configs/firebase'

export default {
  data() {
    return {
        slides: [],
        slideNum: 0,
        currentSlide: {
            link: '/'
        },
        bannerTime: 5000,
        showSlide: true,
        refreshIntervalId: null,
    }
  },
  methods: {
        slidePick(slide, slideID){
            clearInterval(this.refreshIntervalId)
            this.showSlide = false
            this.slideNum = slideID
            this.currentSlide = slide
            this.banner()
            setTimeout(()=>
            {
                this.showSlide = true;
            },200)
        },
      changeSlide()
      {
          this.currentSlide = this.slides[this.slideNum];
          setTimeout(()=>
          {
              this.showSlide = true;
          },200)
      },
      banner()
      {
              this.refreshIntervalId = setInterval(()=>{
                this.slideNum += 1;
                  if(this.slideNum > this.slides.length-1)
                  {
                      this.showSlide = false;
                      this.slideNum = 0;
                      this.changeSlide();
                  }
                  else
                  {
                      this.showSlide = false;
                      this.changeSlide();
                  }
              },this.bannerTime)
      }

  },
  async beforeCreate()
  {
        //Get Categories from db
        await db.collection('SliderRules').doc('Rules').get().then(async (data)=>
        {
            let rules = data.data()
            // Unpack categories
            for(let i=0; i < rules.DisplayQueueV2.length; i++)
            {
                if(rules.DisplayQueueV2[i].active === true){
                    await db.collection('Slides').doc(rules.DisplayQueueV2[i].id).get().then((data)=>
                    {
                        // Unpack categories
                        if(data.data().display === true){
                        this.slides.push(data.data());
                        }
                        
                    }).catch((error)=>
                    {
                        console.log('Error: Cannot get categories. More:')
                        console.log(error);
                    })
                }
            }
            this.currentSlide = this.slides[0]
            this.banner();
            
        }).catch((error)=>
        {
            console.log('Error: Cannot get categories. More:')
            console.log(error);
        })

        // db.collection('Slides').get().then((data)=>
        // {
        //     // Unpack categories
        //     for(let i=0; i < data.docs.length; i++)
        //     {
        //         if(data.docs[i].data().display === true){
        //         this.slides.push(data.docs[i].data());
        //         }
        //     }
        //     this.currentSlide = this.slides[0]
        //     this.banner();
            
        // }).catch((error)=>
        // {
        //     console.log('Error: Cannot get categories. More:')
        //     console.log(error);
        // })
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/variables';

.banner {
    overflow: hidden;
    height: 42vw;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    &__control{
        position: absolute;
        height: 0.64rem;
        width: 100%;
        bottom: 0.52rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__dots{
            display: flex;
            justify-content: center;
            align-content: center;
            &__dot{
                height: 0.64rem;
                width: 0.64rem;
                background: black;
                opacity: 0.2;
                margin-right: 0.41rem;
                border-radius: 100px;
                transition: all 0.4s ease-in-out;
                cursor: pointer;
            }
            &__dot:hover{
                opacity: 0.5 !important;
            }
            &__dot:last-child{
                margin-right: 0;
            }
            &__dotActive{
                opacity: 1 !important;
            }
        }
    }
    &__container {
        width: 100%;
        height: 80vh;
        &__wrapper, &__slide {
            position: relative;
            width: 100%;
        }
        &__slide {
            overflow: hidden;
        }
        &__image {
            height: 80%;
            width: 100%;
            object-fit: cover;
        }
    }
}

@media only screen and (max-width: 768px) {
    .banner {
        height: 50vw;
    &__control{
        &__dots{
            &__dot{
                height: 0.48rem;
                width: 0.48rem;
                background: black;
                opacity: 0.2;
                margin-right: 0.41rem;
                border-radius: 100px;
                transition: all 0.4s ease-in-out;
                cursor: pointer;
            }
        }
    }
    &__container {
        &__wrapper, &__slide {
        }
        &__slide {
        }
        &__image {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
}

</style>